import { TableRow } from 'components/Table';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { escapeNaN, formatAsPercentage } from 'utils/formatting';
import { TotalTableCell } from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage.styles';
import { decimalToPercentage } from 'utils/numbers';

interface RebalanceDetailsTableRowProps {
  rebalanceDetails: Partial<RebalanceDetails>;
  rebalance?: RebalanceWithDetails;
}

export const RebalanceDetailsTotalsTableRow = ({
  rebalanceDetails,
}: RebalanceDetailsTableRowProps) => {
  return (
    <TableRow data-qa-id={`total-row`}>
      <TotalTableCell data-qa-id={`total`}>Total</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.preBalance}-name`}>
        {escapeNaN(rebalanceDetails.preBalance)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.price}-type`} align="right">
        {escapeNaN(rebalanceDetails.price)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.preValue}-type`}>
        {escapeNaN(rebalanceDetails.preValue)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.preWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.preWeight)}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.targetWeight}-type`}
        align="right"
        originalValue={decimalToPercentage(rebalanceDetails.targetWeight || '')}
        showFullValueOnHover
      >
        {formatAsPercentage(rebalanceDetails.targetWeight, 4)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.preDifference}-type`}>
        {formatAsPercentage(rebalanceDetails.preDifference)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.tradeCash}-type`}>
        {escapeNaN(rebalanceDetails.tradeCash)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.tradeCoin}-type`}>
        {escapeNaN(rebalanceDetails.tradeCoin)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.postBalance}-type`}>
        {escapeNaN(rebalanceDetails.postBalance)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.postValue}-type`}>
        {escapeNaN(rebalanceDetails.postValue)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.postWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.postWeight)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.postDifference}-type`}>
        {formatAsPercentage(rebalanceDetails.postDifference)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.action}-type`}>-</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualTrade}-type`}>
        {escapeNaN(rebalanceDetails.actualTrade)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualBalance}-type`}>
        {escapeNaN(rebalanceDetails.actualBalance)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualPostValue}-type`}>
        {escapeNaN(rebalanceDetails.actualPostValue)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualPostWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.actualPostWeight)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualPostWeightDifference}-type`}>
        {formatAsPercentage(rebalanceDetails.actualPostWeightDifference)}
      </TotalTableCell>
    </TableRow>
  );
};
